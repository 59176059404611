// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFound_main__IBAJM {\n    position: absolute;\n    left: 50%;\n    top: 40%;\n    transform: translate(-50%, -50%);\n}\n\n.NotFound_number__vEZCr {\n    text-align: center;\n    font-weight: 700;\n    font-size: 100px;\n}\n\n.NotFound_text__NWOfT {\n    text-align: center;\n    font-size: 20px;\n    font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".main {\n    position: absolute;\n    left: 50%;\n    top: 40%;\n    transform: translate(-50%, -50%);\n}\n\n.number {\n    text-align: center;\n    font-weight: 700;\n    font-size: 100px;\n}\n\n.text {\n    text-align: center;\n    font-size: 20px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "NotFound_main__IBAJM",
	"number": "NotFound_number__vEZCr",
	"text": "NotFound_text__NWOfT"
};
export default ___CSS_LOADER_EXPORT___;
