// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.Header_header__cqmPs {\n    /*색깔 있는게 나은지 없는게 나은지*/\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 30px;\n    background-color: #ffffff7d;\n}\n\n.Header_header__cqmPs>button {\n    width: 60px;\n    height: 25px;\n    border: none;\n    background-Color: rgba(255, 235, 235, 0.84);\n    font-weight: 700;\n    font-size: 12px;\n    box-shadow: rgb(187, 187, 187) 0px 3px 10px 0px;\n    cursor: pointer;\n}\n\n.Header_header__cqmPs>button:hover {\n    background-Color: rgb(255, 255, 255);\n}\n\n.Header_logo__Kbz5s {\n    color: #453a3a;\n    font-family: 'Galada', cursive;\n    font-size: 20px;\n    margin: 2px 0 0 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/utils/Header.module.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,2CAA2C;IAC3C,gBAAgB;IAChB,eAAe;IACf,+CAA+C;IAC/C,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,cAAc;IACd,8BAA8B;IAC9B,eAAe;IACf,oBAAoB;AACxB","sourcesContent":["\n.header {\n    /*색깔 있는게 나은지 없는게 나은지*/\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 30px;\n    background-color: #ffffff7d;\n}\n\n.header>button {\n    width: 60px;\n    height: 25px;\n    border: none;\n    background-Color: rgba(255, 235, 235, 0.84);\n    font-weight: 700;\n    font-size: 12px;\n    box-shadow: rgb(187, 187, 187) 0px 3px 10px 0px;\n    cursor: pointer;\n}\n\n.header>button:hover {\n    background-Color: rgb(255, 255, 255);\n}\n\n.logo {\n    color: #453a3a;\n    font-family: 'Galada', cursive;\n    font-size: 20px;\n    margin: 2px 0 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__cqmPs",
	"logo": "Header_logo__Kbz5s"
};
export default ___CSS_LOADER_EXPORT___;
